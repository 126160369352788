<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#B0D0EF">
        <v-card-text
          class="w-block__body2 primary--text"
          :class="{
            'pa-2': $voicer.isMobile === false,
            'pa-1': $voicer.isMobile === true,
            Subtitle2: $voicer.isMobile,
            Subtitle2: $voicer.isMobile === false,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            On Air, des podcasts exclusifs pour les collaborateurs du groupe AFD
            et des collections grand public à emporter partout !
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <exc-dialog />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'

export default {
  components: { ExcDialog },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.message_welcome_sir {
  border-radius: 6px !important;
  display: flex;
  justify-content: space-between;
}

.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
}
.v-card_text {
  font-size: 20px !important;
}
.v-btn {
  border-radius: 8px;
}

.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
}
.v-card_text {
  font-size: 20px !important;
}
</style>
