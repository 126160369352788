const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '65113922213a2f3ea88cb84f',
    enableSplashScreen: 'wavy',
    enableExperimentalBlocks: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    WFeedBack: {
      secondFeedback: {
        title: "Laisser un message à l'équipe AFD On Air",
        name: 'enregistrement du message',
        type: 'audio',
        maxDuration: 15,
        headCaption: `Vous souhaitez laisser un commentaire sur un épisode ou partager une idée pour un prochain sujet ?`,
        subCaption: `Je suis informé que AFD On Air traite les données recueillies pour
        permettre la diffusion de votre dédicace. Pour en savoir plus,
        reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Merci pour votre message !`,
        pseudonym: {
          label: 'Prénom & nom',
          placeholder: 'Martin Dupont',
          required: true,
        },
        recorderForm: [
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: true,
          },
          {
            label: "J'accepte que mon message soit diffusé dans sur AFD On Air",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
    metadatas: {
      name: 'AFD On Air',
      colorPrimary: '#000091',
      colorAccent: '#E1000F',
      supportEmailAddress: 'support@bubblecast.co',
    },
    analytics: {
      matomo: '43',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '6542b422eed4d8faa4c2c217',
            i18n: {
              fr: {
                title: `Hivernales`,
                description: `[exclusif] Le podcast pour (re)vivre les éditions 2024 & 2025`,
              },
            },
            heading: {
              color: '#14375A',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/hivernales-mobile.png')
                  : require('~/assets/images/hivernales-desktop.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '6776830c6eed17bc34fc54b5',
            },
          },
          {
            type: 'tag',
            tagId: '65e197e690b3f9006cf4cf86',
            i18n: {
              fr: {
                title: 'En immersion',
                description: `[interne] Au plus près des collaborateurs du groupe AFD`,
              },
            },
            heading: {
              color: '#EE7656',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '65e197d8156b450336b881eb',
            i18n: {
              fr: {
                title: 'Moments choisis',
                description: `[interne] Les confidences des collaborateurs du groupe AFD`,
              },
            },
            heading: {
              color: '#96D3DD',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '66d96bdcff04580a9b68b87c',
            i18n: {
              fr: {
                title: 'Les pionnières',
                description: `[interne] Le podcast qui donne la parole à celles qui ont bousculé les codes et fait bouger les lignes à l’AFD`,
              },
            },
            heading: {
              color: '#003050',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '66572b894e2018a50714f772',
            i18n: {
              fr: {
                title: "Et l'éthique dans tout ça ?",
                description: `[interne] Le podcast qui explique le Conseil Éthique`,
              },
            },
            heading: {
              color: '#00983A',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6542b42beed4d8eff3c2c21d',
            i18n: {
              fr: {
                title: 'Grandes lignes',
                description: `Le podcast de la recherche sur le développement de l'AFD`,
              },
            },
            heading: {
              color: 'primary',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/grandes-lignes-mobile.png')
                  : require('~/assets/images/grandes-lignes-desktop.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '6542b435eed4d82f2cc2c223',
            i18n: {
              fr: {
                title: 'Des nouvelles de demain',
                description: `Campus groupe AFD donne la parole à des personnalités du monde entier pour réfléchir à un avenir désirable`,
              },
            },
            heading: {
              color: '#B0D0EF',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/des-nouvelles-de-demain-mobile.png')
                  : require('~/assets/images/des-nouvelles-de-demain-desktop.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '6542b4020ce29a65b6f3a896',
            },
          },
          {
            type: 'tag',
            tagId: '6576d4055d6736a6af543fae',
            i18n: {
              fr: {
                title: 'Six pieds sur Terre',
                description: `D’autres voix pour un monde durable`,
              },
            },
            heading: {
              color: '#133556',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/six-pieds-sur-terre-mobile.png')
                  : require('~/assets/images/six-pieds-sur-terre-desktop.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '6576d44d5d67368c5c543fb3',
              sort: (category) => {
                const findTag = (slug, tags) => {
                  return tags.find((tag) => tag.slug === slug)
                }
                const $clone = require('lodash.clone')
                const clonedCategory = $clone(category)
                const subTags = clonedCategory.tags
                const displaidTags = []
                displaidTags.push(
                  findTag(
                    'premiere-serie-:-comment-vivre-avec-le-vivant',
                    subTags
                  )
                )
                displaidTags.push(
                  findTag("deuxieme-serie-:-de-l'eau-pour-tous", subTags)
                )
                displaidTags.push(
                  findTag(
                    'troisieme-serie-:-quelles-villes-pour-demain',
                    subTags
                  )
                )
                displaidTags.push(
                  findTag(
                    "quatrieme-serie-:-l'ocean-sanctuaire-ou-eldorado",
                    subTags
                  )
                )
                displaidTags.push(
                  findTag(
                    'cinquieme-serie-:-demain-dans-nos-assiettes',
                    subTags
                  )
                )
                displaidTags.push(
                  findTag("sixieme-serie-:-l'appel-de-la-foret", subTags)
                )

                clonedCategory.tags = displaidTags.filter(Boolean)
                return clonedCategory
              },
            },
          },
          {
            type: 'tag',
            tagId: '6542b440eed4d8a7f5c2c229',
            i18n: {
              fr: {
                title: 'Les Sentinelles',
                description: `Des voix d’ailleurs pour un monde en commun`,
              },
            },
            heading: {
              color: '#F49C23',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/sentinelles-mobile.png')
                  : require('~/assets/images/sentinelles-desktop.png')
              },
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
